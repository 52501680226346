

function UploadFiles( whistle, $, params ){

	var me = this;

	me.clickUploadLink = function( ev ){
		$('.uploadFiles').click();
		ev.preventDefault();
		$(this).blur();
	};

	me.changeOfUploadFiles = function(){
 
		var files = $('.uploadFiles').prop('files')
		if( !files ){
			files = [ { 'name' : this.value } ];
		}
		var names = $.map(files, function(val) { return val.name; });
		if( names && names.length ){
			
			$('.fileNames').empty().removeClass('hide');

			$( names ).each( function( ){

				$('.fileNames').append( '<div class="fileToUpload">' +
					"<i class='fa fa-paperclip'></i>" +
					this + '</div>' );
			} );
			$('.fileNames ').append('<button type="button" class="btn btn-default btn-xs fa fa-times removeFilesButton"></button>');
			$('.removeFilesButton').click( me.clickRemoveFilesButton );
		}
	};

	me.clickRemoveFilesButton = function(){
		var control = $(".uploadFiles");
	    control.replaceWith( control = control.clone( true ) );
	    control.val(''); 
	    $('.fileNames ').empty();
	    me.observe();
	}

	me.observe = function(){
		$('.uploadLink').off().click( me.clickUploadLink );
		$('.uploadFiles').off().change( me.changeOfUploadFiles );
	};

	me.observe();
}